var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal card",
      class: { "is-active": _vm.showImportModal },
      staticStyle: { "z-index": "1006 !important" },
    },
    [
      _c("div", { staticClass: "modal-background" }),
      _c("div", { staticClass: "modal-card" }, [
        _c(
          "section",
          { staticClass: "modal-card-body notification" },
          [
            _c(
              "button",
              { staticClass: "delete", on: { click: _vm.closeModal } },
              [_vm._v("close")]
            ),
            _c(
              "div",
              {
                staticClass:
                  "tabs is-toggle is-centered is-rounded is-small is-success",
              },
              [
                _c("ul", [
                  _c("li", { class: { "is-active": _vm.activeTab === 1 } }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            _vm.activeTab = 1
                          },
                        },
                      },
                      [_c("span", [_vm._v("Zones")])]
                    ),
                  ]),
                  _c("li", { class: { "is-active": _vm.activeTab === 2 } }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            _vm.activeTab = 2
                          },
                        },
                      },
                      [_c("span", [_vm._v("Vehicles")])]
                    ),
                  ]),
                ]),
              ]
            ),
            _vm.activeTab === 1
              ? _c("md-list", { staticClass: "md-double-line" }, [
                  _c("h3", { staticClass: "title is-6 has-text-success" }, [
                    _vm._v(" Drop your file here and upload "),
                  ]),
                  _c("div", [
                    _c(
                      "a",
                      {
                        staticClass: "link",
                        attrs: {
                          href: "https://firebasestorage.googleapis.com/v0/b/public-contents.appspot.com/o/SHYFTBASE%2FMAPFLEET%20TEMPLATE%20-%20DISTRIBUTIONZONES.csv?alt=media&token=deb0a4cb-e377-4e10-8f15-66cd94ea70f3",
                          target: "_blank",
                        },
                      },
                      [_vm._v("Download Template")]
                    ),
                  ]),
                  _c("div", { staticClass: "tag is-success" }, [
                    _vm._v("Coming on next release"),
                  ]),
                  _c("div", { staticClass: "is-size-7" }, [
                    _vm._v(
                      " Meanwhile, please email us your completed CSV and our team will set up your database "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.activeTab === 2
              ? _c("md-list", { staticClass: "md-double-line" }, [
                  _c("div", [
                    _c(
                      "a",
                      {
                        staticClass: "link",
                        attrs: {
                          href: "https://firebasestorage.googleapis.com/v0/b/public-contents.appspot.com/o/SHYFTBASE%2FMAPFLEET%20TEMPLATE%20-%20DISTRIBUTIONZONES.csv?alt=media&token=deb0a4cb-e377-4e10-8f15-66cd94ea70f3",
                          target: "_blank",
                        },
                      },
                      [_vm._v("Download Template")]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "dropzone",
                      class: { "dragging-over": _vm.isDragging },
                      on: {
                        dragover: function ($event) {
                          $event.preventDefault()
                          return _vm.handleDragOver.apply(null, arguments)
                        },
                        dragleave: function ($event) {
                          $event.preventDefault()
                          return _vm.handleDragLeave.apply(null, arguments)
                        },
                        drop: function ($event) {
                          $event.preventDefault()
                          return _vm.handleDrop.apply(null, arguments)
                        },
                      },
                    },
                    [
                      !_vm.fileUploaded &&
                      !_vm.importingInProgress &&
                      !_vm.isValidated
                        ? _c(
                            "div",
                            { staticClass: "title is-6 has-text-success" },
                            [_vm._v("Drag and drop an Excel file here")]
                          )
                        : _vm._e(),
                      _vm.importingInProgress && !_vm.isValidated
                        ? _c("div", { staticClass: "loader" }, [
                            _vm._v("Data validating..."),
                          ])
                        : _vm._e(),
                      _vm.fileUploaded &&
                      !_vm.importingInProgress &&
                      !_vm.isValidated
                        ? _c(
                            "div",
                            { staticClass: "title is-7 has-text-warning" },
                            [_vm._v(_vm._s(_vm.errorMessage))]
                          )
                        : _vm._e(),
                      _vm.fileUploaded &&
                      _vm.importingInProgress &&
                      _vm.isValidated
                        ? _c(
                            "div",
                            {
                              staticClass: "button is-success is-small",
                              class: { "is-loading": _vm.isUploading },
                              on: { click: _vm.submitData },
                            },
                            [_vm._v("Submit")]
                          )
                        : _vm._e(),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c("footer"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }