<template>
  <div
    class="modal card"
    style="z-index: 1006 !important"
    v-bind:class="{ 'is-active': showImportModal }"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body notification">
        <button class="delete" @click="closeModal">close</button>
        <div class="tabs is-toggle is-centered is-rounded is-small is-success">
          <ul>
            <li :class="{ 'is-active': activeTab === 1 }">
              <a @click="activeTab = 1">
                <span>Zones</span>
              </a>
            </li>
            <li :class="{ 'is-active': activeTab === 2 }">
              <a @click="activeTab = 2">
                <span>Vehicles</span>
              </a>
            </li>
          </ul>
        </div>
        <md-list class="md-double-line" v-if="activeTab === 1">
          <h3 class="title is-6 has-text-success">
            Drop your file here and upload
          </h3>
          <div>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/public-contents.appspot.com/o/SHYFTBASE%2FMAPFLEET%20TEMPLATE%20-%20DISTRIBUTIONZONES.csv?alt=media&token=deb0a4cb-e377-4e10-8f15-66cd94ea70f3"
              target="_blank"
              class="link"
              >Download Template</a
            >
          </div>
          <div class="tag is-success">Coming on next release</div>
          <div class="is-size-7">
            Meanwhile, please email us your completed CSV and our team will set
            up your database
          </div>
        </md-list>
        <md-list class="md-double-line" v-if="activeTab === 2">
          <div>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/public-contents.appspot.com/o/SHYFTBASE%2FMAPFLEET%20TEMPLATE%20-%20DISTRIBUTIONZONES.csv?alt=media&token=deb0a4cb-e377-4e10-8f15-66cd94ea70f3"
              target="_blank"
              class="link"
              >Download Template</a
            >
          </div>
          <div class="dropzone"
          :class="{ 'dragging-over': isDragging }"
          @dragover.prevent="handleDragOver"
          @dragleave.prevent="handleDragLeave"
          @drop.prevent="handleDrop"
          >
            <div class="title is-6 has-text-success" v-if="!fileUploaded && !importingInProgress && !isValidated">Drag and drop an Excel file here</div>
            <div class="loader" v-if="importingInProgress && !isValidated">Data validating...</div>
            <div class="title is-7 has-text-warning" v-if="fileUploaded && !importingInProgress && !isValidated">{{errorMessage}}</div>
            <div class="button is-success is-small" v-if="fileUploaded && importingInProgress && isValidated" v-bind:class="{ 'is-loading': isUploading }"
            @click="submitData">Submit</div>
          </div>
        </md-list>
      </section>
      <footer></footer>
    </div>
  </div>
</template>
<script>
import { saveAs } from "file-saver";
import XLSX from "xlsx";
import { getPostalCodeByRegion } from "../store/mongoStore";
import axios from 'axios';

export default {
  data() {
    return {
      selectedExportingProvince: "",
      regionsExportList: [
        "AB",
        "BC",
        "MB",
        "NB",
        "NS",
        "ON",
        "PE",
        "QC",
        "SK",
        "NL",
      ],
      regionToName: {
        AB: "Alberta",
        BC: "British Columbia",
        MB: "Manitoba",
        NB: "New Brunswick",
        NL: "Newfoundland and Labrador",
        NS: "Nova Scotia",
        NT: "Northwest Territories",
        NU: "Nunavut",
        ON: "Ontario",
        PE: "Prince Edward Island",
        QC: "Quebec",
        SK: "Saskatchewan",
        YT: "Yukon",
      },
      importingInProgress: false,
      fileUploaded: false,
      isValidated: false,
      activeTab: 1,
      isDragging: false,
      isUploading: false,
      invalidRows: [],
      errorMessage: "",
      vehicles: [],
      showSnackbar: false,
      snackbarMessage: ""
    };
  },
  props: {
    showImportModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.importingInProgress = false;
      this.$emit("closeModal");
    },
    /**
     * Export PDF of Postal Codes Data
     */
    async exportPdf() {
      if (this.selectedExportingProvince === "") {
        this.$notify({
          type: "warn",
          group: "export",
          title: "No selected provice",
          text: "Please select a region",
          duration: 20000,
        });
        return;
      }
      const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      };
      try {
        this.importingInProgress = true;
        // const currentRegion = this.$store.state.region;
        this.showChangingRegionModal = true;
        const binary = await getPostalCodeByRegion(
          this.selectedExportingProvince
        );
        const binaryFile = binary.data;
        console.log("binary:", binary);
        const blob = new Blob([s2ab(binaryFile)], {
          type: "application/octet-stream",
        });
        console.log("blob:", blob);
        saveAs(blob, `zones-postalcode.xlsx`);
        this.showChangingRegionModal = false;
        this.importingInProgress = false;
        this.$notify({
          type: "success",
          group: "zones",
          title: "Export File Success",
        });
        this.showImportModal = false;
      } catch (err) {
        this.showChangingRegionModal = false;
        this.importingInProgress = false;
        this.$notify({
          duration: 100000,
          type: "error",
          group: "zones",
          title: "There was an error on exporting",
        });
      }
    },
    handleDragOver() {
      console.log("drag over")
      this.isDragging = true
    },
    handleDragLeave() {
      console.log("drag leave")
      this.isDragging = false
    },
    async handleDrop(event) {
      this.fileUploaded = true
      this.importingInProgress = true
      const file = event.dataTransfer.files[0];
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        /* validate json to check if all data follows the template(name and data format) like below
         {
          Capacity: number,
          EndLocation: string,
          Name: string,
          Region: string,
          ShiftEnd: string(e.g 08:00),
          ShiftStart: string(e.g 08:00),
          StartLocation: string,
          VehicleType: string,
          VolumeCapacity: number,
          WeightCapacity: number
          }
        */
        const invalidRows = []

        // loop through all the json(array)'s element and check if the data is valid. If not, push the row number to invalidRows array
        // row number starts from 2 because the first row is the title
        // if one of condtion is not met, doesn't need to check other conditions
        for (let i = 0; i < json.length; i++) {
          const element = json[i];
          if (element.Capacity === undefined || typeof element.Capacity !== "number") {
            invalidRows.push(i + 2)
            continue
          }
          if (element.EndLocation === undefined || typeof element.EndLocation !== "string") {
            invalidRows.push(i + 2)
            continue
          }
          if (element.Name === undefined || typeof element.Name !== "string") {
            invalidRows.push(i + 2)
            continue
          }
          if (element.Region === undefined || typeof element.Region !== "string") {
            invalidRows.push(i + 2)
            continue
          }
          if (element.ShiftEnd === undefined || typeof element.ShiftEnd !== "string") {
            invalidRows.push(i + 2)
            continue
          }
          if (element.ShiftStart === undefined || typeof element.ShiftStart !== "string") {
            invalidRows.push(i + 2)
            continue
          }
          if (element.StartLocation === undefined || typeof element.StartLocation !== "string") {
            invalidRows.push(i + 2)
            continue
          }
          if (element.VehicleType === undefined || typeof element.VehicleType !== "string") {
            invalidRows.push(i + 2)
            continue
          }
          if (element.VolumeCapacity === undefined || typeof element.VolumeCapacity !== "number") {
            invalidRows.push(i + 2)
            continue
          }
          if (element.WeightCapacity === undefined || typeof element.WeightCapacity !== "number") {
            invalidRows.push(i + 2)
            continue
          }
        }

        if (invalidRows.length > 0) {
          this.isDragging = false
          this.isValidated = false
          this.importingInProgress = false
          this.invalidRows = invalidRows
          this.errorMessage =
              `Please check the excel file. You have wrong data at the row index ${
                invalidRows.length > 10 ? invalidRows.slice(0, 10).join(", ") + "..."
                : invalidRows.join(", ")}`
        } else {
          this.isValidated = true
          this.vehicles = json
      }
      };
      reader.readAsBinaryString(file);
    },
    async submitData() {
          this.isUploading = true
          await axios.post(
            "http://localhost:5001/metro-logistics/us-central1/MAPPING-importVehicles", { vehicles: this.vehicles }
          ).then(async (s) => {
            if (s.data.status && s.data.status === 400) {
              this.snackbarMessage = "Internal error. Please contact to Shyftbase team"
            } else {
              this.snackbarMessage = "Successfully imported"
            }
            this.showSnackbar = true
          }).catch(error => {
            this.snackbarMessage = "Internal error. Please contact to Shyftbase team"
            this.showSnackbar = true
            console.log("error message", error)
          });

          this.isUploading = false
          this.fileUploaded = false
          this.importingInProgress = false
          this.isValidated = false
          this.isDragging = false
    }
  },
};
</script>
<style>
.dropzone {
  border: 2px dashed black;
  justify-content: center; align-items: center; display: flex;
  padding: 20px;
  height: 100px;
  text-align: center;
}
  .dragging-over {
    border-color: red;
  }
</style>
